<template>
  <div
    :class="fullWidth ? 'full-width' : ''"
    class="row index-head"
  >
    <div
      v-for="column in columns"
      :key="column"
      class="col col-head"
    >
      <div class="q-title text-bold text-grey-8">
        {{ column }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fullWidth: Boolean,
    columns: Array
  }
}
</script>

<style lang="stylus" scoped>
.index-head
  padding 15px 0
  width: 100%
  @media (min-width 1024px)
    width 75%!important
.col-head
  display: flex;
  justify-content: space-between;
  padding-right 40px

  span
    line-height 1.5

.full-width.index-head
  @media (min-width 1024px)
    width 100%!important
</style>
